<template>
  <div class="tc_userRegistration">
    <div class="userReg-layout">
      <!-- 注册 -->
      <div class="userReg" v-if="isReg">
        <div class="userReg-header">
          <p>注册账号</p>
        </div>
        <div class="content">
          <!-- 头像 -->
          <div class="headImg">
            <el-upload
              class="avatar-uploader"
              action="/upload_api"
              :show-file-list="false"
              :on-success="fileUploadApi"
              :data="fileData"
              :before-upload="beforeAvatarUpload"
            >
              <!-- :before-upload="beforeAvatarUpload" -->
              <img v-if="imageUrl" :src="imageUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
          <!-- 个人信息 -->
          <div class="info">
            <el-form
              ref="form"
              :model="form"
              :rules="rules"
              label-width="85px"
              size="mini"
              status-icon
            >
              <el-form-item label="账号:" prop="account">
                <el-input
                  v-model="form.account"
                  maxlength="14"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item label="用户姓名:" prop="name">
                <el-input
                  v-model="form.name"
                  maxlength="12"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <!-- <el-form-item label="身份证号:" prop="idcard">
                <el-input
                  v-model="form.idcard"
                  maxlength="18"
                  show-word-limit
                ></el-input>
              </el-form-item> -->
              <el-form-item label="密码:" prop="password">
                <el-input
                  type="password"
                  v-model="form.password"
                  maxlength="16"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item label="确认密码:" prop="affpassword">
                <el-input
                  type="password"
                  v-model="form.affpassword"
                  maxlength="16"
                  show-word-limit
                ></el-input>
                <el-row type="flex" justify="end">
                  <span class="showPaww" @click="showPaww" v-if="isshowPassword"
                    >显示密码</span
                  >
                  <span
                    class="showPaww"
                    @click="showPaww"
                    v-if="!isshowPassword"
                    >隐藏密码</span
                  >
                </el-row>
              </el-form-item>
              <el-form-item label="性别:" prop="sex">
                <el-radio-group v-model="form.sex">
                  <el-radio :label="1">男</el-radio>
                  <el-radio :label="0">女</el-radio>
                </el-radio-group>
              </el-form-item>
              <!-- <el-form-item label="出生日期:" prop="age">
                <el-date-picker
                  :picker-options="pickerOptions0"
                  v-model="form.age"
                  type="date"
                  placeholder="选择日期"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </el-form-item> -->

              <el-form-item label="年龄" prop="ages">
                <el-input
                  type="number"
                  v-model="form.ages"
                  maxlength="16"
                  :min="0"
                ></el-input>
              </el-form-item>

              <el-form-item label="文化程度:" prop="culture">
                <el-select v-model="form.culture">
                  <el-option label="文盲" value="文盲"></el-option>
                  <el-option label="小学" value="小学"></el-option>
                  <el-option label="初中" value="初中"></el-option>
                  <el-option
                    label="高中/中专/技校"
                    value="高中/中专/技校"
                  ></el-option>
                  <el-option label="大学专科" value="大学专科"></el-option>
                  <el-option label="大学本科" value="大学本科"></el-option>
                  <el-option label="硕士" value="硕士"></el-option>
                  <el-option label="博士及以上" value="博士及以上"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="部门:" prop="department_id">
                <el-select v-model="form.department_id">
                  <el-option
                    :label="item.name"
                    :value="item.id"
                    v-for="item in departmentList"
                    :key="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>

              <!-- 绑定信息 -->
              <el-collapse v-model="activeNames">
                <el-collapse-item title="选填信息" name="1">
                  <!-- 自填信息 -->
                  <template v-for="item in archivesData">
                    <!-- 单行文本 -->
                    <template v-if="item.type === 1">
                      <el-form-item :label="item.name + ':'" :key="item.id">
                        <el-input v-model="form.archives[item.key]"></el-input>
                      </el-form-item>
                    </template>
                    <!-- 下拉框 -->
                    <template v-if="item.type === 3">
                      <el-form-item :label="item.name + ':'" :key="item.id">
                        <el-select v-model="form.archives[item.key]">
                          <el-option
                            :label="item2"
                            :value="item2"
                            v-for="(item2, key) in item.content"
                            :key="key"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </template>
                    <!-- 单选 -->
                    <template v-if="item.type === 4">
                      <el-form-item :label="item.name + ':'" :key="item.id">
                        <el-radio-group v-model="form.archives[item.key]">
                          <el-radio
                            :label="item2"
                            v-for="(item2, key) in item.content"
                            :key="key"
                          ></el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </template>
                    <!-- 复选 -->
                    <template v-if="item.type === 5">
                      <el-form-item :label="item.name + ':'" :key="item.id">
                        <el-checkbox-group v-model="form.archives[item.key]">
                          <el-checkbox
                            :label="item2"
                            v-for="(item2, key) in item.content"
                            :key="key"
                          ></el-checkbox>
                        </el-checkbox-group>
                      </el-form-item>
                    </template>
                    <!-- 文本域 -->
                    <template v-if="item.type === 2">
                      <el-form-item :label="item.name + ':'" :key="item.id">
                        <el-input
                          type="textarea"
                          v-model="form.archives[item.key]"
                          :rows="5"
                        ></el-input>
                      </el-form-item>
                    </template>
                  </template>
                </el-collapse-item>
              </el-collapse>
              <!-- 按钮 -->
              <el-row type="flex" justify="center" class="btn">
                <el-button type="success" @click="gologin"
                  >已有帐号,返回登录</el-button
                >
                <el-button type="primary" @click="createdUser"
                  >立即创建</el-button
                >
              </el-row>
            </el-form>
          </div>
        </div>
      </div>
      <!-- 注册成功 -->
      <transition name="el-fade-in-linear">
        <div class="regSuccess" v-if="!isReg">
          <div class="title">
            <i class="el-icon-check"></i>恭喜你! <i></i>注册成功
          </div>
          <div class="msg">
            <span @click="goHome">开始答题</span>
            <!-- <span @click="gologin">返回登陆页面</span> -->
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { login } from "@/api/login.js";
import { getuserinfo } from "@/api/answer.js";
import {
  getbywhere,
  getUserDepartment,
  register,
  getUserAccount,
} from "@/api/comm.js";
import { exists } from "fs";
// import { async } from "q";
let status = 0;
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.form.affpassword !== "") {
          this.$refs.form.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.password) {
        this.ispassword = false;
        callback(new Error("两次输入密码不一致!"));
      } else {
        this.ispassword = true;
        callback();
      }
    };
    var validateAcc = (rule, value, callback) => {
      if (rule) {
        getbywhere({ account: this.form.account }).then((res) => {
          if (res.msg) {
            callback(new Error("对不起，用户账户已存在"));
          } else {
            callback();
          }
        });
      } else {
        callback();
      }
    };
    // var validateIdc = (rule, value, callback) => {
    //   let reg = /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/;
    //   if (value === "") {
    //     callback(new Error("身份证不能为空"));
    //   } else if (!reg.test(value)) {
    //     callback(new Error("请输入正确的身份证号码"));
    //   } else {
    //     callback();
    //   }
    // };

    return {
      rules: {
        password: [
          {
            required: true,
            min: 4,
            max: 16,
            message: "长度在 4 到 16 个字符",
            trigger: "blur",
          },
          { validator: validatePass, trigger: "change" },
        ],
        affpassword: [
          { required: true, validator: validatePass2, trigger: "change" },
        ],
        account: [
          {
            required: false,
            min: 4,
            max: 14,
            message: "长度在 4 到 14 个字符",
            trigger: "blur",
          },
          { validator: validateAcc, trigger: ["blur", "change"] },
        ],
        // idcard: [
        //   {
        //     required: true,
        //     min: 18,
        //     max: 18,
        //     message: "身份证号长度不符",
        //     trigger: "blur",
        //   },
        //   { validator: validateIdc, trigger: ["blur", "change"] },
        // ],
        sex: [{ required: true, message: "请选择性别", trigger: "change" }],
        culture: [
          { required: true, message: "请选择文化程度", trigger: "change" },
        ],
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        // age: [{ required: true, message: "请选择出生日期", trigger: "blur" }],
        ages: [{ required: true, message: "请输入年龄", trigger: "blur" }],
        department_id: [
          { required: true, message: "请选择部门", trigger: "change" },
        ],
      },
      form: {
        type: 1,
        account: "",
        sex: 1,
        name: "",
        password: "",
        affpassword: "",
        // idcard: "",
        culture: "",
        head_id: "",
        age: "",
        ages: "",
        department_id: "",
        begin_time: 0,
        end_time: 0,
        archives: {},
      },
      archivesData: [],
      status: 0,
      activeNames: ["1"],
      fileData: {
        type: 0,
        attribution_id: 1, // 当前 用户的id
      },
      imageUrl: "./static/img/regtx.png",
      departmentList: [],
      // 控制注册成功状态
      isReg: true,
      ispassword: false,
      isshowPassword: true,
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6; //只能选择今天及今天之前
        },
      },
    };
  },
  created() {
    // ***判断是否是扫描二维码后的用户***
    if (localStorage.getItem("ByQr")) {
      let byQr = JSON.parse(localStorage.getItem("ByQr"));
      // ***判断传递的参数中是否存在部门参数***
      if (byQr.depName != "" && byQr.depId != "") {
        let dep = {
          id: byQr.depId * 1,
          name: byQr.depName,
        };
        // ***如果是，则渲染传递过来的部门数据***
        this.departmentList.push(dep);
        this.form.department_id = dep.id;
      } else {
        // ***如果不是，则对部门下拉框进行加载***
        this.loadDep();
      }
    } else {
      // ***如果不是，则对部门下拉框进行加载***
      this.loadDep();
    }
  },
  mounted() {
    // 自动填写密码账号
    getUserAccount().then((res) => {
      if (res.code == 400200) {
        this.form.password = res.data.password + "";
        this.form.affpassword = res.data.password + "";
        this.archivesData = res.archives;
        this.form.account = res.data.account + "";
      }
    });
  },
  methods: {
    // 验证用户名是否重复
    verifyAcc() {
      let a = false;
      // getbywhere({ account: this.form.account }).then((res) => {});
    },
    // 文件上传成功的狗子
    fileUploadApi(response, file) {
      this.form.head_id = response.data.id;
      this.form.p;
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    // 图片上次校验
    beforeAvatarUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension =
        testmsg === "jpg" ||
        testmsg === "jpeg" ||
        testmsg === "JPG" ||
        testmsg === "png" ||
        testmsg === "PNG" ||
        testmsg === "bpm" ||
        testmsg === "BPM";
      const isLt50M = file.size / 1024 / 1024 < 2;
      if (!extension) {
        this.$message({
          message: "上传图片只能是jpg / png / bpm格式!",
          type: "error",
        });
        return false;
      }
      //console.log(file);
      if (!isLt50M) {
        this.$message({
          message: "上传文件大小不能超过 2MB!",
          type: "error",
        });
        return false;
      }
      return extension || isLt50M;
    },
    // 提交创建
    createdUser() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.form.end_time =
            Date.parse(new Date()) / 1000 + 30 * 60 * 60 * 24;
          // 创建用户 后 自动登录
          this.form.age = this.form.ages;
          register(this.form).then((res) => {
            if (res.code == 400200) {
              this.isReg = false;
              // localStorage.removeItem("ByQr");
              let param = {
                userName: this.form.account,
                password: this.form.password,
                type: 1,
              };
              // 自动登录
              login(param).then((res) => {
                console.log(res);
                if (res.code === 400200) {
                  // ---------获取用户ID------------------
                  getuserinfo().then((res) => {
                    if (res.code == 400200) {
                      this.userInfo = res.data;
                      localStorage.setItem("user_id", this.userInfo.id);
                    }
                  });
                }
              });
            }
          });
        } else {
          this.$message({
            message: "请检查带 * 的内容！",
            type: "warning",
          });
          return false;
        }
      });
    },
    // 前往答题页面
    goHome() {
      localStorage.setItem("token", "admintoken");
      this.$router.push("/answer_content/answer_list");
    },
    gologin() {
      localStorage.setItem("isReg", 2);
      this.$router.push("/userLogin");
    },
    // 显示密码
    showPaww() {
      let arr = document.querySelectorAll("input");
      if (this.isshowPassword) {
        arr[3].type = "text";
        arr[4].type = "text";
        this.isshowPassword = false;
      } else {
        arr[3].type = "password";
        arr[4].type = "password";
        this.isshowPassword = true;
      }
    },
    // 加载部门
    loadDep() {
      getUserDepartment().then((res) => {
        if (res.code === 400200) {
          this.departmentList = res.data.data;
        }
      });
    },
  },
};
</script>

<style lang="less">
.tc_userRegistration {
  width: 100%;
  zoom: 1;
  box-sizing: border-box;
  height: 100%;
  .userReg-layout {
    max-width: 768px;
    min-width: 325px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .userReg-header {
    // height: 50px;
    flex: 1;
    text-align: center;
    background: rgb(217, 237, 238);
    p {
      font-size: 25px;
      color: rgb(37, 37, 37);
      line-height: 50px;
      font-weight: 700;
      text-shadow: #ffffff 2px 2px;
    }
  }
  .content {
    box-shadow: black 0px 2px 15px;
    // height: calc~ "(100% - 50px)";
    flex: 13;
    // height: 100%;
    // 头像
    background: white;
    .headImg {
      padding-top: 10px;
      display: flex;
      justify-content: center;
      border-radius: 50%;
      .avatar-uploader .el-upload {
        border: 1px solid rgb(38, 103, 114);
        border-radius: 50px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
      }
      .avatar-uploader .el-upload:hover {
        border-color: #409eff;
      }
      .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 80px;
        height: 80px;
        line-height: 80px;
        text-align: center;
      }
      .avatar {
        width: 80px;
        height: 80px;
        display: block;
      }
    }
    .info {
      padding: 0px 20px;
      padding-top: 20px;
      .btn {
        position: fixed;
        width: 100%;
        margin-top: 20px;
        bottom: 0;
      }
      // 抽屉
      .el-collapse {
        .el-collapse-item__header {
          border-top: 1px rgb(38, 103, 114) solid;
          background: transparent;
        }
        .el-collapse-item__wrap {
          background: transparent;
        }
      }
    }
  }
  .regSuccess {
    background: rgb(203, 237, 240);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .title {
      font-weight: 700;
      font-size: 30px;
      color: green;
    }
    .msg {
      margin: 60px;
      display: flex;
      justify-content: space-between;
      span {
        margin: 0 20px;
        font-size: 16px;
        font-weight: 700;
        cursor: pointer;
      }
    }
  }
  .showPaww {
    color: rgb(110, 110, 110);
    cursor: pointer;
  }
}
</style>